/*
 * @Author: 鲁金伟 lujinwei@hikvision.com.cn
 * @Date: 2022-05-07 09:54:47
 * @LastEditors: 鲁金伟 lujinwei@hikvision.com.cn
 * @LastEditTime: 2022-05-09 17:01:59
 * @FilePath: \btmedtec\src\nav.config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    title: 'Home',
    router: '/index',
    menuCode: '001',
    icon: 'h-icon-menu'
  },
  {
    title: 'player',
    router: '/player',
    menuCode: '001',
    icon: 'h-icon-menu'
  }
]
