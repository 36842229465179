<template>
  <h-page id="app">
    <!-- <h-page-menu :menu="menu" /> -->
    <router-view />
  </h-page>
</template>

<script>
import navList from './nav.config.js'
export default {
  name: 'App',
  computed: {
    menu() {
      navList.forEach(nav => {
        nav.title = this.$t ? this.$t(nav.title) : nav.title
      })
      const { userInfo } = this.$store.state
      if (!userInfo) return []
      // 展示有权限的菜单项
      return navList.filter(nav => {
        return (userInfo.code || []).includes(`${process.env.VUE_APP_CONTEXT}_${nav.menuCode}`)
      })
    },
  },
}
</script>

<style lang="scss">
html, body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  min-width: 1280px;
  font-size: 1rem;
  font-weight: 400;
  font-family: sofia-pro,sans-serif;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }
body, button, input, select, textarea { outline: none;}
</style>
