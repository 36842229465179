/*
 * @Author: 鲁金伟 lujinwei@hikvision.com.cn
 * @Date: 2022-05-07 09:54:47
 * @LastEditors: 鲁金伟 lujinwei@hikvision.com.cn
 * @LastEditTime: 2022-05-09 17:01:52
 * @FilePath: \btmedtec\src\router.config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: '/error/:type',
    name: 'Error',
    component: 'Error', // 注意提供ErrorPage组件内的多语言翻译
  },
  {
    name: 'index',
    path: '/index/',
    menuCode: '001',
    component: 'index',
  },
  {
    name: 'player',
    path: '/player/',
    menuCode: '001',
    component: 'player',
  }
]
